import * as React from "react"


const IncludeLocation = () => {
    return (

        <section className="section section--location" id={'location'}>

            <div className="section__content">
                <h3 className="heading heading--section">Praxis</h3>

                <p className={'section__paragraph'}>Als Osteopathin ist es mir ein besonderes Anliegen Sie zusammen mit
                    Ihren Kindern in der individuellen Entwicklung und des Seins und Werdens zu unterstützen. Deshalb
                    nehme ich mir pro Behandlungseinheit 50 - 60 Minuten für Sie Zeit.</p>

                <p className={'section__paragraph'}>Die erste Behandlung beginnt mit einem ausführlichen
                    Anamnesegespräch, Befunderhebung, Diagnosestellung und mündet direkt in die Therapie. In jeder
                    weiteren Behandlungseinheit beginnen wir mit einem kurzen Feedback Ihrerseits, Überprüfung des
                    Befundes und gehen direkt in die Behandlung über. Kinder, die sich mir selbst mitteilen möchten,
                    bekommen ausreichend Raum und Zeit, dies zu tun. Die Anzahl der Behandlungen sind individuell
                    unterschiedlich. Den Behandlungsplan erstelle ich nach Absprache mit Ihnen gemeinsam.</p>

                <p className={'section__paragraph'}>
                    <div className={'heading heading--subline '}>Wichtig</div>
                    <ul className={'list'}>
                        <li className={'list__item'}>Bitte bringen Sie zu jeder Behandlung ein großes Handtuch oder bei
                            Wickelkindern zusätzlich eine wasserundurchlässige Wickelunterlage mit. Damit unterstützen
                            Sie die Umwelt, weil dadurch Einwegauflagen vermieden werden.
                        </li>
                        <li className={'list__item'}>Für Kinder kann es wichtig sein, Bekanntes und Liebgewonnenes bei
                            sich zu haben. Bitte denken Sie auch an ein Lieblingsspielzeug, Schmusetier, Schnuffeltuch
                            etc. Es stehen natürlich in der Praxis auch Spielsachen zur Verfügung.
                        </li>
                        <li className={'list__item'}>Denken Sie bitte auch an den Infektionsschutz und Ihre Maske.
                            Kinder sind von dieser Regelung ausgenommen. Entnehmen Sie die aktuellen Vorschriften bitte
                            den Medien. Desinfektionsmittel stehen in der Praxis zur Verfügung.
                        </li>
                        <li className={'list__item'}>Sollten Sie einen Termin nicht einhalten können, sagen Sie ihn
                            bitte mindestens 24 Stunden vorher ab.
                        </li>
                        <li className={'list__item'}>Bitte bringen Sie zum ersten Termin den Aufnahmebogen und die
                            Patientenerklärung ausgefüllt und unterschrieben mit.
                        </li>
                    </ul>

                    <p className={'section__paragraph'}>Die Rechnungsstellung erfolgt mittels der GebüH an Sie direkt.
                        Beihilfe- und Privatkassen erstatten die Kosten meist ganz oder teilweise. Auch einige
                        gesetzliche Krankenkassen bezuschussen die osteopathischen Behandlungen nach Vorleistung durch
                        den Patienten. Bitte informieren Sie sich rechtzeitig bei Ihrer Kasse ob und in welchem Umfang
                        Ihre Kasse die Behandlung erstattet. Eventuell brauchen Sie eine Behandlungsempfehlung durch
                        Ihren behandelnden Arzt. Die gesetzlichen Vorgaben sind durch meine Ausbildungen erfüllt.
                        Dies wird durch meine Mitgliedschaft im Verband der Osteopathen Deutschland e.V. bestätigt.
                        <a href={'https://www.osteopathie.de/therapeutenliste?thnr=318941'} title={'Eintrag beim Verband der Osteopathen Deutschland e.V.'}
                           rel={'noreferrer'}
                           target={'_blank'} className={'logo logo--vod'}>VOD</a>
                    </p>
                </p>

            </div>
        </section>
    )
};

export default IncludeLocation;
