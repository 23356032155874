import * as React from "react"
import DataTherapie from "../content/therapie.json"

const IncludeTherapie = () => {
    return (
        <section className="section section--therapie " id={'therapie'}>
            <div className="section__content">
                <h3 className="heading heading--section">
                    {DataTherapie.title}
                </h3>

                <p className={'section__paragraph'}>
                    Die Osteopathie ist eine ganzheitliche, sanfte Heilmethode die sich der Hände des Therapeuten sowohl
                    zur Diagnosestellung, als auch zur Therapie bedient. Als eine ganz eigene Disziplin hat sich die
                    Kinderosteopathie aus der Osteopathie heraus entwickelt. Es bedarf einer speziellen
                    Zusatzausbildung, da Babys und Kinder nicht als kleine Erwachsene angesehen werden können. Teilweise
                    unterscheidet sich die Anatomie noch stark von der der Erwachsenen.
                </p>
                <p className={'section__paragraph'}>
                    Babys und Kinder drücken sich auf ihre ganz eigene Art aus. Nicht immer können wir die Anliegen der
                    Jüngsten direkt erfassen. So kann beispielsweise ein Schreien, ein Schlaf- oder Stillproblem,
                    Nervosität oder Unkonzentriertheit bei Kindern und Jugendlichen viele Ursachen haben. Oft sind
                    Blockaden verantwortlich für Unwohlsein und andere Leiden der Kleinen und jungen Erwachsenen. Durch
                    das sanfte Lösen von Blockaden ist die Osteopathie eine ideale Ergänzung zur schulmedizinischen
                    Behandlungsform.
                </p>
                <p className={'section__paragraph'}>
                    Der Osteopath kann helfen die kindliche Ausdrucksform zu übersetzen und in ein ganzheitliches Bild
                    zu rücken.
                </p>
                <p className={'section__paragraph'}>
                    Auch Frauen vor oder während der Schwangerschaft kann Osteopathie helfen. Durch die Veränderungen
                    der
                    Körperstatik, hormonelle Umstellung oder Vorerkrankungen können sich unangenehme oder schmerzhafte
                    Beschwerden einstellen. Stress oder Sorgen während der Schwangerschaft verschaffen sich nicht selten
                    durch körperliche Symptomatik oder Nervosität Ausdruck. Das Ziel der Osteopathie ist es in diesen
                    Fällen
                    ein homöostatisches Gleichgewicht herzustellen und Beschwerden zu lindern.
                </p>
                <p className={'section__paragraph'}>
                    Mögliche Behandlungsindikationen:
                </p>

                <h4 className="heading heading--listheadline">Bei Frauen:</h4>

                <ul className={'list'}>
                    <li className={'list__item'}>Kinderwunsch</li>
                    <li className={'list__item'}>Menstruationsbeschwerden</li>
                    <li className={'list__item'}>Schwangerschaftsbeschwerden wie Erbrechen, Stress oder Schmerzen am
                        Bewegungsapparat
                    </li>
                    <li className={'list__item'}>Kopfschmerzen und Migräne</li>
                    <li className={'list__item'}>Beckenbeschwerden</li>
                    <li className={'list__item'}>Geburtsvorbereitung</li>
                    <li className={'list__item'}>Optimierung der Kindslage</li>
                    <li className={'list__item'}>Stillbeschwerden</li>
                    <li className={'list__item'}>Geburtsverletzungen</li>
                    <li className={'list__item'}>Narbenbehandlung</li>
                </ul>

                <h4 className="heading heading--listheadline">Bei Kindern:</h4>
                <ul className={'list'}>
                    <li className={'list__item'}>Kopf-Vorzugshaltung, Kopfverformung und Asymmetrien</li>
                    <li className={'list__item'}>Stillprobleme</li>
                    <li className={'list__item'}>Schreibabys</li>
                    <li className={'list__item'}>Anpassungsstörungen</li>
                    <li className={'list__item'}>Dreimonatskoliken</li>
                    <li className={'list__item'}>Schlafstörungen</li>
                    <li className={'list__item'}>Enuresis</li>
                    <li className={'list__item'}>Entwicklungsverzögerung (z.B. Sprache oder Motorik)</li>
                    <li className={'list__item'}>Infektanfälligkeit, ständig wiederkehrende Infekte</li>
                    <li className={'list__item'}>Asthma</li>
                    <li className={'list__item'}>Lernstörungen</li>
                    <li className={'list__item'}>ADS/ ADHS</li>
                    <li className={'list__item'}>Unterstützung bei Versorgung mit Zahnspangen</li>
                    <li className={'list__item'}>Gesundheitsvorsorge und Prävention</li>
                </ul>

                <p className={'section__paragraph'}>
                    Wenn Sie wissen möchten, ob Osteopathie auch für Sie oder Ihr Kind geeignet ist, setzen Sie sich
                    gerne
                    telefonisch oder per Email mit mir in Verbindung.

                </p>

            </div>
        </section>
    )
};

export default IncludeTherapie;
