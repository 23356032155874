import * as React from 'react';
import {Helmet} from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import About from '../components/about';
import Therapie from '../components/therapie';
import Contact from '../components/contact';
import Location from '../components/location';
import Seo from '../components/Seo';

import '../styles/main.less';

// markup
const IndexPage = () => {
  return (
      <>
          <Seo/>
        <Helmet htmlAttributes={{lang: 'de'}}>
          <meta charSet="utf-8"/>
          <title>Kinderosteopathie Praxis Karin Eideloth in Utting am Ammersee</title>
          <link rel="canonical" href="https://kinderosteopathie.bayern/"/>
          <meta name="description"
                content="Osteopathie Praxis von Karin Eideloth für Kinder, Jugendliche und Mütter in Utting am Ammersee "/>
          <meta name="keywords"
                content="Osteopathie, Kinderosteopathie, Kinder, Jugendliche, Schwangere, Mütter, Karin Eideloth, Utting am Ammersee, Utting, Ammersee, München, Landsberg am Lech, Bayern"/>
          <meta name="image" content={'/static/images/logo.png'}/>
          <meta property="og:image" content={'/static/images/logo.png'}/>
          <meta name="twitter:card" content={'/static/images/logo.png'}/>
          <meta name="twitter:image" content={'/static/images/logo.png'}/>

          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'ProfessionalService',
              'image': ['https://kinderosteopathie.bayern/favicon.svg'],
              '@id': 'https://kinderosteopathie.bayern',
              'name': 'Praxis für Kinderosteopathie Karin Eideloth',
              'address': {
                '@type': 'PostalAddress',
                'addressLocality': 'Utting am Ammersee',
                'addressRegion': 'BY',
                'addressCountry': 'DE',
                'postalCode': '86919',
                'streetAddress': 'Schondorfer Straße 15a'
              },
              'geo': {
                '@type': 'GeoCoordinates',
                'latitude': 48.0278152,
                'longitude': 11.0830282
              },
              'url': 'https://Kinderosteopathie.bayern/Utting',
              'telephone': '+4981939989553'
            })}
          </script>

        </Helmet>

        <Header/>

        <main className={'main'}>

          <Therapie/>
          <About/>
          <Location/>
          <Contact/>

        </main>

        <Footer/>
      </>
  );
};

export default IndexPage;
