import * as React from "react"
import DataContact from "../content/contact.json"
import Pin from '../svg/pin.svg';
import Logo from '../svg/logo.svg';

const IncludeContact = () => {
    return (
        <section className="section section--contact" id={'contact'}>
            <div className={'logo logo--contact'}>
                <Logo />
            </div>
            <div className="section__content">
                <h3 className="heading heading--section heading--section">{DataContact.title}</h3>
                <address className={"address address--center"}>
                    {DataContact.content.map((data, index) => {
                        if (data.href) {
                            return <div className={`address__line address__line--${data.type}`}
                                        key={`content_item_${index}`}>
                                <a href={data.href}>{data.line}</a>
                            </div>
                        } else {
                            return <div className={`address__line address__line--${data.type}`}
                                        key={`content_item_${index}`}>{data.line}</div>
                        }
                    })}


                    <br/><br/><br/>


                    <Pin/><br/>
                    <a title={"Route via Google zu Herzog-Wilhelm-Str. 25 (1. Stock) 80331 München"}
                       target={'_blank'}
                       className={'address__link'}
                       rel={'noreferrer'}
                       href={'https://maps.app.goo.gl/BTL2J53wPx2StNWT9'}>
                        Die Praxis in Google Maps</a>

                    <br/>
                    <a title={"Route via Bing zu Herzog-Wilhelm-Str. 25 (1. Stock) 80331 München"}
                       rel={'noreferrer'}
                       className={'address__link'}
                       target={"_blank"}
                       href={"https://www.bing.com/maps?osid=6b46734b-eedb-40a5-bcbe-b68eff046f29&cp=48.135473~11.564038&lvl=16.69&pi=0&v=2&sV=2&form=S00027"}>
                        Die Praxis in Bing Maps
                    </a>

                </address>

            </div>
        </section>

    )
};

export default IncludeContact;
