import * as React from "react"
import DataAbout from "../content/about.json"
import { StaticImage } from "gatsby-plugin-image";

const IncludeAbout = () => {
    return (
        <section className="section section--about" id={'about'}>
            <div className="section__content">
                <div className={'section__heading'}>
                    <h3 className="heading heading--section">{DataAbout.title}</h3>
                </div>

                <div className={'section__image'}>
                    <StaticImage class={'image image--about'} alt={'Karin Eideloth'}
                                 width={640}
                                 src='../images/karin-eideloth.jpg'></StaticImage>
                </div>


                {DataAbout.content.map((data, index) => {
                    return <p className={'section__paragraph'} key={`content_item_${index}`}>{data.paragraph}</p>
                })}

                <div className="quote">
                    <div className="quote__text">
                        <span dangerouslySetInnerHTML={{__html: DataAbout.quote[0].text}}></span>
                    </div>
                    <div className="quote__author">{DataAbout.quote[0].author}</div>
                </div>

            </div>
        </section>
    )
};

export default IncludeAbout;
